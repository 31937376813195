import React, { useEffect } from "react"
import Layout from "../components/layout"
import BlogHeader from "./BlogHeader"
import { graphql, Link, useStaticQuery } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../components/Breadcrumbs"
import { useTranslation } from "react-i18next"
import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs"
import SEO from '../components/seo'
// import ShareButtons from "../components/ShareButtons"
// import Zoom from "react-medium-image-zoom"
// import "react-medium-image-zoom/dist/styles.css"

const SingleBlogTemplate = ({
  pageContext,
  data,
  data: {
    postEn,
    postMn,
    enGeneral,
    PrevPostMn,
    PrevPostEn,
    NextPostMn,
    NextPostEn,
  },
}) => {
  // console.log(data)
  // const { postEn, postMn, enGeneral } = data ?? undefined
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const mn = currentLanguage === "mn"
  const {
    index: postIndex,
    prevIdEn,
    prevIdMn,
    nextIdMn,
    nextIdEn,
  } = pageContext

  // const prev = mn ? prevMn : prevEn
  // const next = mn ? nextMn : nextEn
  // console.log(prev)

  const {
    title: titleEn,
    author: authorEn,
    blogType: blogTypeEnRaw,
    content: contentEnRaw,
    excerpt: excerptEn,
    seo: seoEn,
    slug: slugEn,
    tags: tagsEnRaw,
  } = postEn ?? undefined

  const authorNameEn = authorEn?.name ?? undefined
  const authorJobEn = authorEn?.job ?? undefined
  const blogTypeEn = blogTypeEnRaw?.type ?? undefined

  const contentEnMd = contentEnRaw?.markdown ?? undefined
  const contentEn = (
    <div className="px-4 prose-sm sm:prose">
      <ReactMarkdown children={contentEnMd} />
    </div>
  )

  const tagsEn = tagsEnRaw.map((item, index) => {
    return (
      <div
        className="px-2 py-1 font-semibold text-gray-800 bg-gray-200 rounded-full"
        key={index}
      >
        {item}
      </div>
    )
  })

  const {
    title: titleMn,
    author: authorMn,
    blogType: blogTypeMnRaw,
    content: contentMnRaw,
    excerpt: excerptMn,
    seo: seoMn,
    slug: slugMn,
    tags: tagsMnRaw,
  } = postMn ?? undefined

  const authorNameMn = authorMn?.name ?? undefined
  const authorJobMn = authorMn?.job ?? undefined
  const blogTypeMn = blogTypeMnRaw?.type ?? undefined

  const contentMnMd = contentMnRaw?.markdown ?? undefined
  const contentMn = (
    <div className="px-4 prose-sm sm:prose">
      <ReactMarkdown children={contentMnMd} />
    </div>
  )

  const tagsMn = tagsMnRaw.map((item, index) => {
    return (
      <div
        className="px-2 py-1 font-semibold text-gray-800 bg-gray-200 rounded-full"
        key={index}
      >
        {item}
      </div>
    )
  })

  const { date, coverImage, author: authorPicture } = enGeneral ?? undefined

  const coverSharp = coverImage ? getImage(coverImage) : undefined
  const cover = coverSharp ? (
    <GatsbyImage
      image={coverSharp}
      alt="cover image"
      className="object-cover w-full h-full border-none outline-none select-none focus:outline-none"
    />
  ) : undefined

  const authorSharp = authorPicture?.picture
    ? getImage(authorPicture.picture)
    : undefined
  const authorImage = authorSharp ? (
    <GatsbyImage image={authorSharp} alt="Author image" className="w-full" />
  ) : undefined

  const title = mn ? titleMn : titleEn
  const authorName = mn ? authorNameMn : authorNameEn
  const authorJob = mn ? authorJobMn : authorJobEn
  const blogType = mn ? blogTypeMn : blogTypeEn
  const content = mn ? contentMn : contentEn
  const excerpt = mn ? excerptMn : excerptEn
  const slugBlog = mn ? slugMn?.trim() : slugEn?.trim()
  const tags = mn ? tagsMn : tagsEn

  const prev = mn ? PrevPostMn : PrevPostEn
  const next = mn ? NextPostMn : NextPostEn

  return (
    <Layout dots>
      <SEO title={title} />
      <BlogHeader
        image={cover}
        tall
        title={title}
        text={excerpt}
        authorImage={authorImage}
        authorName={authorName}
        date={date}
      />
      <div className="relative z-20 flex flex-col items-center max-w-screen-lg mb-20 rounded md:bg-white md:shadow-xl md:mx-auto">
        <div className="justify-center py-10 md:px-20">
          {/* {cleanContent} */}
          {content}
          {/* <div className="flex justify-center mt-10 s9-widget-wrapper"></div> */}
        </div>
      </div>

      <div className="justify-between flex-1 max-w-screen-lg mx-4 mb-10 space-y-4 md:mx-auto sm:flex sm:space-y-0 ">
        {prev?.title && (
          <div
            className={`${
              prev ? "" : "pointer-events-none select-none text-gray-100"
            }`}
          >
            <Link
              to={`/blog/${prev?.slug}`}
              className="flex items-center space-x-2 font-semibold rounded shadow justify-evenly group sm:shadow-none hover:underline"
            >
              <div className="p-4">
                <BsChevronDoubleLeft className="w-6 h-6 text-gray-600 sm:text-gray-400 sm:group-hover:text-gray-800" />
              </div>
              <div className="pr-8 text-sm text-gray-800 sm:text-gray-600 sm:group-hover:text-black sm:text-base">
                {prev.title}
              </div>
            </Link>
          </div>
        )}
        {next?.title && (
          <div
            className={`${
              next ? "" : "pointer-events-none select-none text-gray-100"
            }`}
          >
            <Link
              to={`/blog/${next?.slug}`}
              className="flex items-center space-x-2 font-semibold rounded shadow justify-evenly group hover:underline sm:shadow-none"
            >
              <div className="pl-8 text-sm text-gray-800 sm:text-gray-600 sm:group-hover:text-black sm:text-base">
                {next.title}
              </div>
              <div className="p-4">
                <BsChevronDoubleRight className="w-6 h-6 text-gray-600 sm:text-gray-400 sm:group-hover:text-gray-800" />
              </div>
            </Link>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SinglePost(
    $idImage: String
    $idEn: String
    $idMn: String
    $prevIdEn: String
    $prevIdMn: String
    $nextIdMn: String
    $nextIdEn: String
  ) {
    PrevPostMn: graphCmsPost(id: { eq: $prevIdMn }) {
      slug
      title
    }
    PrevPostEn: graphCmsPost(id: { eq: $prevIdEn }) {
      slug
      title
    }
    NextPostMn: graphCmsPost(id: { eq: $nextIdMn }) {
      slug
      title
    }
    NextPostEn: graphCmsPost(id: { eq: $nextIdEn }) {
      slug
      title
    }
    postEn: graphCmsPost(id: { eq: $idEn }) {
      title
      slug
      date
      blogType {
        type
      }
      excerpt
      content {
        markdown
      }
      tags
      author {
        name
        job
      }
      seo {
        title
        description
        keywords
        image {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    postMn: graphCmsPost(id: { eq: $idMn }) {
      title
      slug
      blogType {
        type
      }
      excerpt
      content {
        markdown
      }
      tags
      author {
        name
        job
      }
      seo {
        title
        description
        keywords
        image {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    enGeneral: graphCmsPost(id: { eq: $idImage }) {
      date
      coverImage {
        gatsbyImageData(width: 1000, layout: CONSTRAINED)
      }
      author {
        picture {
          gatsbyImageData(width: 100, layout: CONSTRAINED)
        }
      }
    }
  }
`

export default SingleBlogTemplate
