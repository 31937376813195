import { Link } from "gatsby"
import React from "react"
import Breadcrumbs from "../components/Breadcrumbs"
import PageTitle from "../components/PageTitle"
import { BlogText } from "../graphql/QueryById"
import { GoQuote } from "react-icons/go"

const BlogHeader = ({ title, text, image, authorImage, authorName, date }) => {
  return (
    <div className="relative z-10 max-w-screen-lg mx-auto">
      <PageTitle title={title} />
      <Breadcrumbs productType={BlogText()} productName={title} nameEn="blog" />
      {/* <div className="max-w-screen-lg mx-auto "> */}
      {/* <Link
        to="/blog"
        className="hidden px-10 pt-2 pb-2 text-sm font-semibold tracking-wider uppercase md:pt-10 text-brand bg-gray-50 md:text-base md:block"
      >
        Блог & Нийтлэл
      </Link> */}

      <div
        className={`relative   justify-center mx-auto bg-gray-100 h-80 md:min-h-md grid md:grid-cols-2 md:px-10 md:py-14 shadow-xl md:gap-2
       ${image ? "max-w-screen-xl" : ""}`}
      >
        <div className="flex flex-col">
          {/* {title && (
              <div
                className={`z-10 inline max-w-lg px-2  rounded  decoration-clone  py-4 text-center md:text-left md:py-0 ${
                  image
                    ? "text-gray-800 font-semibold  md:text-4xl text-lg"
                    : "font-semibold text-gray-800 text-xl md:text-2xl"
                }`}
              >
                {title}
              </div>
            )} */}

          <div className="relative items-center hidden space-x-5 md:flex">
            {authorImage && (
              <div className="flex w-20 h-20 overflow-hidden rounded-full">
                {authorImage}
              </div>
            )}
            <div>
              {authorName && (
                <div className="hidden font-semibold uppercase text-brand md:block">
                  {authorName}
                </div>
              )}
              {date && (
                <div className="hidden text-sm text-gray-600 md:block">
                  {date}
                </div>
              )}
            </div>
          </div>
          {text && (
            <div
              className={`z-10 md:inline max-w-sm px-2  text-sm md:text-base  md:max-w-lg mt-10 hidden relative border-l-4 border-brand pl-4 border-opacity-50 ${
                image ? "md:text-gray-800 text-white" : "text-gray-500"
              }`}
            >
              {/* <div className="absolute inset-y-0 left-0 z-0 flex h-full text-gray-200 opacity-80">
                <GoQuote className="w-20 h-full" />
              </div> */}
              <div className="relative z-10">{text}</div>
            </div>
          )}
        </div>
        {image && (
          <div className="relative flex object-cover w-full mx-auto overflow-hidden border-none outline-none select-none h-80 focus:outline-none">
            {image}
            {/* <div className="absolute inset-0"></div> */}
          </div>
        )}
      </div>
      {/* </div> */}
    </div>
  )
}

export default BlogHeader
